<template>
  <div class="footernav">
    <!-- <div class="btn_box" @click="handleBookBtn">Book a demo</div> -->
    <!-- <div class="bottom_link">
      <span>Copyright 2021 © Arcanite</span>
      <span @click="goRouter('/privacyPolicy')">Privacy Policy</span>
      <span @click="goRouter('/termsAndConditions')">Terms & Conditions</span>
    </div> -->
    <div class="explore_part">
      <div class="explore_row">
        <div class="explore">Explore</div>
        <div class="resources">Resources</div>
      </div>
      <div class="explore_row_item">
        <div class="explore" @click="goRouter('/Features')">Features</div>
        <div class="resources" @click="goRouter('/Blogs')">Blogs</div>
      </div>
      <div class="explore_row_item">
        <div class="explore" @click="goRouter('/Pricing')">Pricing</div>
        <div class="resources">
          <a href="https://apps.apple.com/au/app/arc-agent/id1510107896" target="_blank">
            <p>Agent App (iPhone)</p>
          </a>
        </div>
      </div>
      <div class="explore_row_item">
        <div class="explore" @click="goRouter('/AboutUs')">About Us</div>
        <div class="resources">
          <a href="https://play.google.com/store/apps/details?id=au.com.arcnet.agent " target="_blank">
            <p>Agent App (Android)</p>
          </a>
        </div>
      </div>
      <div class="explore_row_item">
        <div class="explore" @click="goRouter('/Arcanite')">Arcanite</div>
        <div class="resources">
          <a href="https://apps.apple.com/au/app/arcanite-connect/id1598271065" target="_blank">
            <p>Admin App (iPhone)</p>
          </a>
        </div>
      </div>
      <div class="explore_row_item">
        <div class="explore" @click="goRouter('/ArcaniteConnect')">Arcanite Connect</div>
        <div class="resources">
          <a href="https://play.google.com/store/apps/details?id=au.com.arcnet.admin" target="_blank">
            <p>Admin App (Android)</p>
          </a>
        </div>
      </div>
      <div class="explore_row_item">
        <div class="explore" @click="goRouter('/ArcanitePartners')">Arcanite Partners</div>
        <div class="resources">
          <a href="https://agent.arcnet.com.au/" target="_blank">
            <p>Agent Web Login</p>
          </a>
        </div>
      </div>
      <div class="explore_row_item">
        <div class="explore" @click="goRouter('/DragonVR')">DragonVR</div>
		<div class="resources">
		  <a href="https://agency.arcnet.com.au/" target="_blank">
		    <p>Admin Web Login</p>
		  </a>
		</div>
      </div>
      <div class="explore_row_item">
        <!-- <div class="explore" @click="goRouter('/Equimate')">Equimate</div> -->
        <div class="explore" @click="goRouter('/Marketplace')">Marketplace</div>
		<div class="resources">
		  <a href="https://partner.arcnet.com.au/" target="_blank">
		    <p>Partner Portal Login</p>
		  </a>
		</div>
      </div>
      <div class="explore_row_item">
        <!-- <div class="explore" @click="goRouter('/Marketplace')">Marketplace</div> -->
      </div>
      <div class="explore_row" style="margin-top:20px;">
        <div class="explore">Contact</div>
      </div>
      <div class="explore_row_item">
        <a href="mailto:support@arcnet.com.au">
        <div class="explore">support@arcnet.com.au</div>
        </a>
      </div>
      <div class="explore_row_item">
        <div style="width:100%;" class="resources">66 Berry St, North Sydney NSW 2060 Australia</div>
      </div>
      <div class="ready_to_start">
        Ready to get started?
      </div>
      <div class="book_a_demo" :class="[isID?'IDbook_a_demo':'']">
        <span @click="showPopupWidget">{{ $t("home.book_a_demo") }}</span>
        <img src="./../../assets/imgs/pc/footer/arrorRight.png" alt="">
      </div>
      <!-- <div class="sign_up_now">Sign Up Now</div> -->
      <div class="ready_to_start">
        Follow Us
      </div>
      <div class="the_links">
        <div class="icons">
          <a href="https://www.linkedin.com/company/arc-au/" target="_blank"><img src="./../../assets/imgs/pc/footer/linkin.png" /></a>
          <a href="https://www.instagram.com/arcanite.au/" target="_blank"><img src="./../../assets/imgs/pc/footer/ins.png" /></a>
          <a href="https://www.facebook.com/people/Arcanite/100076342056431/" target="_blank"><img src="./../../assets/imgs/pc/footer/facebook.png" /></a>
          <a href="https://twitter.com/Arcanite_au" target="_blank"><img src="./../../assets/imgs/pc/footer/twitter.png" /></a>
        </div>
      </div>
      <div class="ready_to_start">Country</div>
      <div class="countryIcons">
          <div class="ImgSpace" v-if="value=='au'"><img class="countryImgOutSide" style="margin:0;" src="./../../assets/imgs/pc/footer/australia.png"/></div>
          <div class="ImgSpace" v-if="value=='id'"><img class="countryImgOutSide" style="margin:0;" src="./../../assets/imgs/pc/footer/indonesia.png"/></div>
          <el-select class="selectStyle" v-model="value" @change="changeLanguage">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              >
              <div class="countryOptions">
                <div v-if="item.value=='au'"><img class="countryImg" style="height:24px;width:24px;" src="./../../assets/imgs/pc/footer/australia.png"/></div>
                <div v-if="item.value=='id'"><img class="countryImg" sytle="height:24px;width:24px;" src="./../../assets/imgs/pc/footer/indonesia.png"/></div>
                <div class="countryOptionsText">{{item.label}}</div>
              </div>
            </el-option>
          </el-select>
        </div>

    </div>
    <div class="bottom_links">
      <img src="@/assets/imgs/app/aboutus/arc_logo.png" alt="">
      <!-- <img src="" alt=""> -->
      <div class="bottom_links_items">
        <span>© 2023 Arcanite</span>
        <span @click="goRouter('/PrivacyPolicy')">Privacy Policy</span>
        <span @click="goRouter('/TermsAndConditions')">Terms & Conditions</span>
      </div>
    </div>
  </div>
</template> 
<script>
import { replaceUrl } from "@/assets/js/utility.js";

export default {
  data() {
    return {
      isID:false,
      value: 'au',
      options: [{
        value: 'au',
        label: 'AU'
      }, {
        value: 'id',
        label: 'ID',
        // disabled: true
      }],
      userId: 0,
      name: "",
      email: "",
      isShowInner: false,
      show_items: ["Features", "Blogs", "Pricing", "iPhone App", "About Us"]
    };
  },
  created() {
    if(localStorage.getItem('country')=="id"){
      this.isID=true;
    }else{
      this.isID=false;
    }
    this.value = localStorage.getItem('country') || 'au'
    // this.userId = new Date().getTime();
    this.userId = 'arcweb' + new Date().getTime() + this.MathRand();

  },
  mounted() {
    // this.$intercom.boot({
    //   user_id: this.userId,
    //   name: this.name,
    //   email: this.email
    // });
  },
  // created() {
  // },
  watch: {
    // email(email) {
    //   this.$intercom.update({ email });
    // }
  },
  methods: {
    changeLanguage(){
      let country = localStorage.getItem('country')
      let url;
      let host = window.location.host;

      if(this.value == country){
        return;
      }else if((this.value != country) && this.value == "au"){
        sessionStorage.setItem('doubleCheckID','0')
        if(host.indexOf('staging')>-1){
            let auhost = host.split('arcanite.id')
            url = auhost[0].concat('arcnet.com.au');
        } else{
            url = replaceUrl(host, 'au');
        }
        let path = window.location.protocol + '//' + url
        if(window.location.href.indexOf('http://localhost')>-1){
          var testurl = 'localhost:8080/'
          window.location.href = window.location.protocol + '//' + testurl
        }else{
          window.location.href = path
        }
        localStorage.setItem('country','au')
        
      }else if((this.value != country) && this.value == "id"){
        sessionStorage.setItem('doubleCheckID','1')
        let idhost
        if(host.indexOf('staging')>-1){
          idhost = host.split('arcnet.com.au')
          url = idhost[0].concat('arcanite.id')
        }else{
          url = replaceUrl(host, 'id');
        }
        let path = window.location.protocol + '//' + url
        // window.HubSpotConversations.widget.remove(); 
        if(window.location.href.indexOf('http://localhost')>-1){
          var testurl = 'localhost:8080/'
          window.location.href = window.location.protocol + '//' + testurl
        }else{
          window.location.href = path
        }
        localStorage.setItem('country','id')
      }
      setTimeout(() => {
        location.reload();
      }, 500);   
    },
    goRouter(path) {
      this.$router.push(path);
    },
    MathRand() {
      var Num = "";
      for (var i = 0; i < 6; i++) {
        Num += Math.floor(Math.random() * 10);
      }
      return Num
    },
    // handleBookBtn() {
    //   this.$intercom.show();
    // },
    showPopupWidget() {
      if(this.isID){
        Calendly.showPopupWidget('https://calendly.com/erni-yap');
      }else{
        Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
      }
      return false;
    },
  }
};
</script>
<style lang="scss" scoped>
.footernav {
  // background-color: #fff;
  // height: 137px;
  width: 100%;
  box-sizing: border-box;
  // position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 0px 0;
  z-index: 90;
  color: #fff !important;
  .btn_box {
    margin: 0 auto;
    width: 80%;
    height: 47px;
    background-color: #5291f7;
    border-radius: 6px;
    box-shadow: 0 2px 20px 0 rgba(82, 145, 247, 0.5);
    line-height: 47px;
    font-family: Poppins;
    font-size: 14px;
    color: #fff;
  }
  .bottom_links {
    border-top: 1px solid #E6EBF2;
    padding-top: 20px;
	  padding-bottom: 20px;
  }
  .sign_up_now {
    margin-top: 30px;
    border: solid 1px #fff;
    font-family: Poppins-Bold;
    font-size: 16px;
    border-radius: 5px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 42px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .bottom_link {
    // margin-top: 42px;
    font-family: Arial;
    font-size: 12px;
    // color: #9b9b9b;
    color: red;
    text-align: left;
    span {
      margin-right: 25px;
    }
  }
  .the_links {
    .icons {
      display: flex;
      margin-top: 20px;
      img {
        width: 24px;
        height: 24px;
        margin: 10px 28px 0 0px;
      }
    }
  }
  .book_a_demo {
    align-items: center;
    img {
      width: 22px;
      height: 22px;
      margin-left: 10px;
    }
  }
  

  .explore_part {
    height: 944px;
    background-color: #FFFFFF;
    padding-left: 26px;
    text-align: left;
    padding-top: 50px;
    // padding-bottom: 40px;
    .explore_row {
      .explore {
        display: inline-block;
        width: 40vw;
        font-family: Poppins-Bold;
        font-size: 16px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        color: #062440;
      }
      .resources {
        display: inline-block;
        width: 40vw;
        font-family: Poppins-Bold;
        font-size: 16px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        color: #062440;
        p {
          font-family: Poppins-Bold;
          font-size: 16px;
          // font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          text-align: left;
          color: #67788C;
        }
      }
    }
    .explore_row_item {
      margin-top: 20px;
      // margin-right: 26px;
      .explore {
        display: inline-block;
        width: 40vw;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #67788C;
		white-space: nowrap;
      }
      .resources {
        display: inline-block;
        width: 40vw;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #67788C;
        p {
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: #67788C;
        }
      }
    }
    .ready_to_start {
      margin-top: 40px;
      font-family: Poppins-Bold;
      font-size: 20px;
      // font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      text-align: left;
      color: #062440;
    }
    .countryIcons{
      display: flex;
      margin-top: 20px;
      img{
        width: 24px;
        height: 24px;
        margin:10px 28px 10px 0px;
      }
    }
    .ImgSpace{
      height: 24px;
      width:24px;
      margin:0 !important;
    }
    
    

    
    .countryImgOutSide{
      height:24px;
      width:24px;
      padding-top: 8px;
    }
    .book_a_demo {
      margin-top: 30px;
      width: 175px;
      height: 42px;
      border-radius: 5px;
      padding-left: 16px;
      display: flex;
      background-color: #1890ff;
      box-sizing: border-box;
      span {
        font-family: Poppins-Bold;
        font-size: 16px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
    }
    .IDbook_a_demo{
      width: 212px;
    }
    // .book_a_demo:hover{
      
    // }
    .sign_up_now {
      width: 135px;
      height: 42px;
    }
  }
  .bottom_links {
    height: 123px;
    background-color: #FFFFFF;
	  display: flex;
	  flex-direction: column;
    img {
	    padding-left: 21px;
      width: 104px;
      height: 43px;
	    flex-grow: 0;
    }
    .bottom_links_items {
      padding-left: 21px;
      // padding-right: 21px;
	  // padding-bottom: 20px;
      display: flex;
	    flex-wrap:wrap;
      margin-top: 10px;
      justify-content: flex-start;
	  // flex-direction: column;
	  // align-items: space;
	  // align-self: stretch;
      span {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #67788C;
		    padding:10px;
		    white-space: nowrap;
      }
    }
  }
}
</style>

<style lang="scss">

.countryOptions{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
  gap:8px;
}
.countryOptionsText{
  font-family: 'Poppins-bold';
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.012em;
  color: #314455;
}
.countryImg{
  height:24px;
  width:24px;
}

.selectStyle{
  width: 99px;
  .el-input__inner{
    border:none;
    font-family: 'Poppins-bold';
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.012em;
    color: #314455;
    padding-left:8px;
    padding-right:30px;
  }

  .el-input__icon{
    color: #314455 !important;
    font-weight:700;
  }
}
</style>